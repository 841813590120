import { UploadFileEntity } from "~/generated-types";
import { ID } from "~/types/general.types";
import { AvatarResponse } from "~/types/user.types";

type ImageFormatType = "large" | "medium" | "small" | "thumbnail";

export const getFullUrl = (partial?: string): string => {
    // Convert nullish to empty string to force string return
    if (!partial) {
        return "";
    }

    // If provided is already a full URL, return it
    if (partial.startsWith("http")) {
        return partial;
    }

    // Otherwise return the partial URL with the file prefix
    return `${process.env.NEXT_PUBLIC_API_BASE}${partial}`;
};

export const getImageUrl = (
    entity: UploadFileEntity | AvatarResponse | undefined | null,
    format: ImageFormatType,
    isAvatar: boolean = false,
): string | null => {
    // If no entity or necessary attributes are found, return null
    if (!entity) return null;

    // If it's an avatar entity, use the avatar-specific logic
    if (isAvatar) {
        const { formats } = entity as AvatarResponse;

        // If the requested format is found return it
        if (formats?.[format]?.url) return getFullUrl(formats[format].url);

        // Fallback to the original url, if the desired format didn't match
        return getFullUrl((entity as AvatarResponse)?.url);
    }

    // For non-avatar entities, use the original logic
    const { formats } = (entity as UploadFileEntity)?.attributes || {};

    // If the requested format is found return it
    if (formats?.[format]?.url) return getFullUrl(formats[format].url);

    // Fallback to the original url, if the desired format didn't match
    return getFullUrl((entity as UploadFileEntity)?.attributes?.url);
};

export type ImageEntity = {
    fullImageUrl: string;
    id: number;
    name: string;
    primary: boolean;
    slide: number;
    thumbnailUrl: string;
};

export const sortAvatars = (avatarArr: AvatarResponse[] | undefined): AvatarResponse[] | null => {
    if (!avatarArr) return null;

    return avatarArr.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
};

export const getImages = (raw: UploadFileEntity[] = [], primaryImageId: ID) => {
    const all: ImageEntity[] = [];

    raw.forEach((image, index) => {
        const thumbnailUrl = getImageUrl(image, "small");
        const fullImageUrl = getImageUrl(image, "large");

        if (!thumbnailUrl || !fullImageUrl || !image.id || !image.attributes) {
            return;
        }

        all.push({
            fullImageUrl,
            id: parseInt(image.id.toString()),
            name: image.attributes.name ?? "",
            primary: parseInt(image.id.toString()) === parseInt(primaryImageId?.toString()),
            slide: index + 1,
            thumbnailUrl,
        });
    });

    const primary = all.find((image) => image.primary);

    return { all, primary: primary ?? all[0] ?? null };
};
