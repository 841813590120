import { ForwardRefExoticComponent, RefAttributes, SVGProps } from "react";
import {
    Enum_Componentaccessmodule_Name,
    Enum_Componentaccesspermission_Object,
    Enum_Report_Type,
    Enum_Report_Generation_Type,
    Enum_Report_Generation_Day,
} from "~/generated-types";
import { Locale } from "~/i18n/routing";

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

export type ID = string | number;
export type Icon = ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, "ref"> & {
        title?: string | undefined;
        titleId?: string | undefined;
    } & RefAttributes<SVGSVGElement>
>;

export type AccessAction = "create" | "read" | "update" | "delete";

export interface UploadMutationVariables {
    file: File;
    ref: string;
    refId: ID;
    field: string;
}

export interface PaginationMeta {
    page: number;
    pageCount: number;
    pageSize: number;
    total: number;
}

export interface ResponseMeta {
    pagination: PaginationMeta;
}

export interface PageProps {
    params: { locale: Locale; id: ID };
}

export type GenericObject = { [key: string]: any };

export type GenerateMetadataProps = {
    params: { locale: string; id?: string };
};

export type PlatformType = null | "mac" | "windows" | "linux";

export const weekDaysSortMap = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
};

export {
    Enum_Componentaccessmodule_Name as AccessModule,
    Enum_Componentaccesspermission_Object as AccessObject,
    Enum_Report_Type as ReportType,
    Enum_Report_Generation_Type as ReportGenerationType,
    Enum_Report_Generation_Day as ReportGenerationDay,
};
