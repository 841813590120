"use client";

import { useEffect } from "react";
import { signOut } from "next-auth/react";

const LogoutComponent = () => {
    useEffect(() => {
        signOut();
    }, []);

    return null;
};

export default LogoutComponent;
