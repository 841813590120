"use client";

import { gql, useMutation } from "@apollo/client";
import {
    BellIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CircleHelpIcon,
    ClipboardListIcon,
    Wrench,
    CpuIcon,
    GroupIcon,
    HomeIcon,
    MapIcon,
    SettingsIcon,
    TruckIcon,
    BookUser,
} from "lucide-react";
import { cva } from "class-variance-authority";
import { usePostHog } from "posthog-js/react";
import React, { FC, useContext, useMemo } from "react";
import { setUserData } from "~/actions/user.actions";
import { UserContext } from "~/context/User.context";
import { UsersPermissionsUserInput } from "~/generated-types";
import useGeneralErrorToast from "~/hooks/useGeneralErrorToast";
import { AccessModule, ID } from "~/types/general.types";
import SidebarNavigationItem from "./SidebarNavigationItem";
import SidebarUser from "./SidebarUser";
import { useTranslations } from "next-intl";
import { Link } from "~/i18n/routing";

const UPDATE_USER = gql`
    mutation UpdateUser($id: ID!, $input: UsersPermissionsUserInput!) {
        updateUsersPermissionsUser(id: $id, data: $input) {
            data {
                id
                attributes {
                    collapsed_sidebar
                }
            }
        }
    }
`;

const collapseStyles = cva(
    "absolute bg-white py-1 z-10 cursor-pointer rounded text-gray-600 md:hover:bg-gray-100 transition-colors border border-gray-200",
    {
        variants: {
            collapsed: {
                true: "bottom-4 right-0 translate-x-1/2",
                false: "bottom-[1.4rem] right-4",
            },
        },
    },
);

const CollapseButton: FC = () => {
    const { state, dispatch } = useContext(UserContext);
    const posthog = usePostHog();

    const [updateUser, { error }] = useMutation<any, { id: ID; input: UsersPermissionsUserInput }>(UPDATE_USER);
    useGeneralErrorToast(error);

    async function toggleCollapsed() {
        const newCollapse = !state.data.collapsedSidebar;

        dispatch(setUserData({ ...state.data, collapsedSidebar: newCollapse }));

        await updateUser({
            variables: {
                id: state.data.id,
                input: {
                    collapsed_sidebar: newCollapse,
                },
            },
        });

        posthog.capture("Sidebar toggled", { collapsed: newCollapse });
    }

    return (
        <div className={collapseStyles({ collapsed: state.data.collapsedSidebar })} onClick={toggleCollapsed}>
            {state.data.collapsedSidebar ? (
                <ChevronRightIcon className="h-4 w-4" />
            ) : (
                <ChevronLeftIcon className="h-4 w-4" />
            )}
        </div>
    );
};

const containerStyles = cva("hidden md:flex flex-col bg-white border border-r-gray-200 relative", {
    variants: {
        collapsed: {
            true: "p-3",
            false: "p-4 w-64 overflow-hidden",
        },
    },
});

const AppSidebar = () => {
    const t = useTranslations();
    const { state } = useContext(UserContext);

    const homeLogo = useMemo(() => {
        return (
            <Link href="/" className="mb-4">
                <div className="flex items-center gap-3 font-bold">
                    <img alt="SporingsGruppen Logo" src="/logo-square-color.svg" className="h-9 w-9 rounded-md" />
                    {!state.data.collapsedSidebar && (
                        <span>
                            <span className="font-bold">Sporings</span>
                            <span className="font-light">Gruppen</span>
                        </span>
                    )}
                </div>
            </Link>
        );
    }, [state.data.collapsedSidebar]);

    return (
        <aside className={containerStyles({ collapsed: state.data.collapsedSidebar })}>
            {homeLogo}

            <nav className="flex flex-1 flex-col gap-1">
                <SidebarNavigationItem
                    href="/"
                    exact={true}
                    icon={<HomeIcon />}
                    label={t("dashboard")}
                    collapsed={state.data.collapsedSidebar}
                />
                <SidebarNavigationItem
                    href="/units"
                    icon={<TruckIcon />}
                    label={t("units")}
                    collapsed={state.data.collapsedSidebar}
                    module={AccessModule.Units}
                />
                <SidebarNavigationItem
                    href="/beacons"
                    icon={<CpuIcon />}
                    label={t("beacons")}
                    collapsed={state.data.collapsedSidebar}
                    module={AccessModule.Beacons}
                />
                <SidebarNavigationItem
                    href="/map"
                    icon={<MapIcon />}
                    label={t("map")}
                    collapsed={state.data.collapsedSidebar}
                    module={AccessModule.Map}
                />
                <SidebarNavigationItem
                    href="/alarms"
                    icon={<BellIcon />}
                    label={t("alarms")}
                    collapsed={state.data.collapsedSidebar}
                    module={AccessModule.Alarms}
                />
                <SidebarNavigationItem
                    href="/groups"
                    icon={<GroupIcon />}
                    label={t("groups")}
                    collapsed={state.data.collapsedSidebar}
                    module={AccessModule.Groups}
                />
                <SidebarNavigationItem
                    href="/reports/single"
                    icon={<ClipboardListIcon />}
                    label={t("reports")}
                    collapsed={state.data.collapsedSidebar}
                    module={AccessModule.Reports}
                />
                <SidebarNavigationItem
                    href="/service"
                    icon={<Wrench />}
                    label={t("service")}
                    collapsed={state.data.collapsedSidebar}
                    module={AccessModule.Service}
                />
                <SidebarNavigationItem
                    href="/people"
                    icon={<BookUser />}
                    label={t("employees")}
                    collapsed={state.data.collapsedSidebar}
                    module={AccessModule.People}
                />
                <SidebarNavigationItem
                    href="/cases"
                    icon={<CircleHelpIcon />}
                    label={t("cases")}
                    collapsed={state.data.collapsedSidebar}
                    module={AccessModule.Cases}
                />

                <div role="separator" className="mt-auto" />

                <SidebarNavigationItem
                    href="/settings/general"
                    icon={<SettingsIcon />}
                    label={t("settings")}
                    collapsed={state.data.collapsedSidebar}
                    module={AccessModule.Settings}
                />
            </nav>

            <SidebarUser />
            <CollapseButton />
        </aside>
    );
};

export default AppSidebar;
