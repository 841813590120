import(/* webpackMode: "eager", webpackExports: ["AdminToolbarWrapper"] */ "/codebuild/output/src1297922097/src/web-app/components/admin/toolbar/admin-toolbar-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1297922097/src/web-app/components/App/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1297922097/src/web-app/components/App/TabBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1297922097/src/web-app/components/logout-component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/codebuild/output/src1297922097/src/web-app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1297922097/src/web-app/providers/apollo-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1297922097/src/web-app/providers/permissions-provider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1297922097/src/web-app/providers/posthog.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1297922097/src/web-app/providers/react-query-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1297922097/src/web-app/providers/session-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserDataTransferWrapper"] */ "/codebuild/output/src1297922097/src/web-app/providers/user-data-transfer-wrapper.tsx");
